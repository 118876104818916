import React, { useState, useEffect, createContext, useContext } from 'react';
import { uploadToR2 } from './uploadToR2'; // Import the uploadToR2 function
import { auth } from './firebase'; // Import the authentication instance
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; // Import Google Auth functions
import { serverTimestamp, onSnapshot, query, orderBy, collection, updateDoc, doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import { firestore, addDoc } from "./firebase"; // Import Firestore functions
import CoordinateSystem from './CoordinateSystem'; // Importiere die neue Komponente

// Create a context for image processing
const ImageProcessingContext = createContext();

const Tryon = () => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [outputUrl, setOutputUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null); // State to hold the uploaded file
    const [successMessage, setSuccessMessage] = useState(''); // State to hold the success message
    const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image
    const [coordinateImages, setCoordinateImages] = useState([]); // Zustand für die Bilder im Koordinatensystem
    const [imageUrl, setImageUrl] = useState(''); // Zustand für die URL des neuen Bildes
    const [selectedModelImage, setSelectedModelImage] = useState(null); // Zustand für die ausgewählte Bild-URL
    const [images, setImages] = useState([]); // Zustand für die abgerufenen Bilder
    const [imageUrlRb, setImageUrlRb] = useState(''); // Zustand für die imageUrlRb
    const [combinedImage, setCombinedImage] = useState(null); // Zustand für das kombinierte Bild
    const [modelPrompt, setModelPrompt] = useState(''); // Zustand für den Texteingang
    const [loadingMessageModelPrompt, setLoadingMessageModelPrompt] = useState(''); // Zustand für die Ladeanzeige
    const [userId, setUserId] = useState(''); // Added for userId
    const [docRefId, setDocRefId] = useState(''); // Added for docRefId
    const [generatedModelImageUrls, setGeneratedModelImageUrls] = useState([]); // Zustand für die generierten Bild-URLs
    const [upscaledImageUrl, setUpscaledImageUrl] = useState(''); // Zustand für die upscaledImageUrl
    
    // Funktion zum Abrufen der Bilder aus Firestore
    const fetchImages = () => {
        const user = auth.currentUser; // Hole den aktuellen Benutzer
        console.log("Current user:", user); // Debugging-Log
        if (!user) {
            console.log("No user is logged in."); // Debugging-Log
            return;
        }

        const imagesRef = collection(firestore, "users", user.uid, "images");
        const q = query(imagesRef, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const imagesData = [];
            querySnapshot.forEach((doc) => {
                const imageData = doc.data();
                const imageId = doc.id;
                imagesData.push({ id: imageId, ...imageData });
            });
            setImages(imagesData); // Setze die abgerufenen Bilder

            // Setze das ausgewählte Modellbild, wenn es existiert
            const choosedImage = imagesData.find(image => image.selectedModelImage);

            /*if (choosedImage) {
                setSelectedModelImage(choosedImage.selectedModelImage); // Setze das ausgewählte Modellbild
                console.log("pups Selected model image set:", choosedImage.selectedModelImage); // Debugging-Log
            } else {
                console.log("No choosed model image found."); // Debugging-Log
            }*/

            // Setze die imageUrlRb, wenn sie existiert und noch nicht gesetzt ist
            const imageUrlRb = imagesData.find(image => image.imageUrlRb);
            if (imageUrlRb) {
                setImageUrlRb(imageUrlRb.imageUrlRb); // Setze die imageUrlRb
                console.log("Image URL for RB:", imageUrlRb.imageUrlRb); // Debugging-Log
            } else {
                console.log("No imageUrlRb found."); // Debugging-Log
            }

            // Überprüfe und setze die upscaledImageUrl, wenn sie existiert
            const upscaledImage = imagesData.find(image => image.upscaledImageUrl);
            if (upscaledImage) {
                setUpscaledImageUrl(upscaledImage.upscaledImageUrl); // Setze die upscaledImageUrl
                console.log("Upscaled Image URL:", upscaledImage.upscaledImageUrl); // Debugging-Log
            } else {
                console.log("No upscaledImageUrl found."); // Debugging-Log
            }
        });

        return () => unsubscribe(); // Aufräumen des Listeners
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                console.log("User is logged in:", user.uid);
                fetchImages(); // Rufe die Funktion zum Abrufen der Bilder auf, wenn der Benutzer angemeldet ist
            } else {
                console.log("No user is logged in.");
            }
        });

        return () => unsubscribe(); // Aufräumen des Listeners
    }, []);

    useEffect(() => {
        if (selectedModelImage) {
            // Überprüfen, ob das Bild bereits im Array vorhanden ist
            const imageIndex = images.findIndex(image => image.url === selectedModelImage);
            if (imageIndex !== -1) {
                // Wenn das Bild bereits vorhanden ist, aktualisiere nur die Position
                setImages(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[imageIndex] = {
                        ...updatedImages[imageIndex],
                        x: 50, // Neue Position, wenn nötig
                        y: 50, // Neue Position, wenn nötig
                    };
                    return updatedImages;
                });
            } else {
                // Wenn das Bild nicht vorhanden ist, ersetze das erste Bild (oder füge es hinzu, wenn das Array leer ist)
                setImages((prevImages) => [
                    { url: selectedModelImage, x: 50, y: 50, width: 100, height: 100 }, // Standardposition und -größe
                    ...prevImages.filter(image => image.url !== selectedModelImage), // Entferne das alte Bild, falls vorhanden
                ]);
            }
        }
    }, [selectedModelImage]); // Abhängigkeit: Wenn selectedModelImage sich ändert

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            if (selectedFile.type.startsWith('image/')) {
                setImage(URL.createObjectURL(selectedFile)); // Zeige das Bild an
                setError('');
                setFile(selectedFile); // Speichere die Datei für die spätere Verwendung
            } else {
                setError('Please upload a valid image file.');
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Verhindere das Standardverhalten
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider(); // Erstelle einen Google Auth Provider
        try {
            const result = await signInWithPopup(auth, provider); // Melde den Benutzer an
            const user = result.user; // Hole den Benutzer
            console.log('User signed in:', user.email); // Logge die E-Mail-Adresse des Benutzers
        } catch (error) {
            console.error("Error during Google sign-in:", error.message); // Fehlerbehandlung
            setError(error.message); // Setze die Fehlermeldung
        }
    };

    const handleGoClick = async () => {
        console.log('handleGoClick called'); // Debugging-Log
        const user = auth.currentUser; // Hole den aktuellen Benutzer
        if (!user) {
            handleGoogleSignIn(); // Wenn der Benutzer nicht angemeldet ist, melde ihn an
        } else {
            console.log('Current user ID:', user.uid); // Überprüfe die Benutzer-ID

            if (file) {
                try {
                    const link = await uploadToR2(file); // Lade die Datei hoch und erhalte den Link
                    console.log('Uploaded image URL:', link); // Logge die URL
                    setSuccessMessage('Image uploaded and Firestore entry created successfully!'); // Setze die Erfolgsmeldung
                    setImage(null); // Leere das Bild nach erfolgreichem Upload
                    setFile(null); // Leere den Dateizustand

                    // Aktuelle URL abrufen und überprüfen, ob eine Image-ID vorhanden ist
                    const url = window.location.href; // Aktuelle URL abrufen
                    const urlParts = url.split('/'); // URL in Teile aufteilen
                    const docRefIdFromUrl = urlParts[7]; // docRefId ist der siebte Teil

                    // Hier wird die URL des ausgewählten Bildes korrekt gesetzt
                    const imageData = {
                        userId: user.uid,
                        createdAt: serverTimestamp(),
                        status: "created",
                        creditCost: 1, // Assuming a default credit cost
                        imageUrl: link, // Store the image URL
                        selectedModelImage: selectedModelImage // Füge die URL des ausgewählten Bildes hinzu
                    };

                    const imagesCollectionRef = collection(firestore, "users", user.uid, "images");

                    if (docRefIdFromUrl) {
                        // Wenn eine Image-ID vorhanden ist, aktualisiere den bestehenden Eintrag
                        const imageRef = doc(imagesCollectionRef, docRefIdFromUrl);
                        await updateDoc(imageRef, imageData); // Aktualisiere die Felder
                        console.log('Image entry updated in Firestore:', docRefIdFromUrl);
                    } else {
                        // Wenn keine Image-ID vorhanden ist, erstelle einen neuen Eintrag
                        const docRef = await addDoc(imagesCollectionRef, imageData);
                        console.log('Image entry created in Firestore:', docRef.id);

                        // Setze die outputUrl mit userId und docRef.id
                        const newOutputUrl = `/tryon/user/${user.uid}/image/${docRef.id}`;

                        // Lösche die bestehende URL und setze die neue URL
                        window.history.replaceState({}, '', newOutputUrl); // Ändere die URL im Browser

                        // Setze die outputUrl
                        setOutputUrl(newOutputUrl); // Setze die outputUrl
                    }

                    // Leere den Zustand, um das Drag-and-Drop erneut zu ermöglichen
                    setImage(null); // Leere das Bild nach dem Upload
                    setFile(null); // Leere den Dateizustand

                } catch (error) {
                    console.error('Upload failed:', error);
                    setError('An error occurred while uploading the image: ' + error.message);
                }
            } else {
                setError('Please upload an image first.');
            }
        }
    };

    const handleImageSelect = (imageUrl) => {
        setSelectedModelImage(imageUrl); // Setze die ausgewählte Bild-URL
        console.log("New selected model image set:", imageUrl); // Debugging-Log
    };

    const IMAGE_URL_1 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/clarityai-fJCxH0c5hyMBtMnGPHuGGPXOMto2-rEaiWVl5GZPNI8DSoFkS.jpg";
    const IMAGE_URL_2 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/out-2-2-1%20Kopie.png";
    const IMAGE_URL_3 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/clarityai-fJCxH0c5hyMBtMnGPHuGGPXOMto2-DW2yyaRvk7GAMVBBUKvf.jpg";

    // Neue Bild-URLs hinzufügen
    const ADDITIONAL_IMAGE_URL_1 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/replicate-prediction-88htseebpdrg80ckmz09m9a188.png";
    const ADDITIONAL_IMAGE_URL_2 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/out-3-2-2.png";
    const ADDITIONAL_IMAGE_URL_3 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/replicate-prediction-xvx06jwnz1rgc0ckn0xbyfxv0w.jpg";

    
    /*const handleChooseModelImage = (selectedModelImage) => {
        // Setze nur das neue Modellbild
        setSelectedModelImage(selectedModelImage);
        console.log("New choosed model image set:", selectedModelImage);
    };*/
    

     const handleCombineImages = async () => {
        if (selectedModelImage && image) {
            try {
                const response = await fetch('/your-combine-endpoint', { // Ersetze mit deinem tatsächlichen Endpunkt
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        newModelImageUrl: selectedModelImage,
                        imageUrlRb: image, // Hier die URL des Bildes, das du hochgeladen hast
                    }),
                });

                const data = await response.json();
                if (data.image) {
                    // Setze das kombinierte Bild in den Zustand
                    setCombinedImage(data.image);
                }
            } catch (error) {
                console.error('Error combining images:', error);
            }
        } else {
            console.error('Please select both model and necklace images.');
        }
    };
      

    // Funktion zum Abrufen des kombinierten Bildes
    const fetchCombinedImage = async () => {
        const response = await fetch('/your-endpoint'); // Ersetze mit deinem tatsächlichen Endpunkt
        const data = await response.json();
        setCombinedImage(data.combined_image); // Setze den Base64-String in den Zustand
    };

    // Funktion zum Generieren der Model-Images
    const handleGenerateModelImages = async () => {
        const user = auth.currentUser; // Hole den aktuellen Benutzer

        // Überprüfe, ob kein Bild aus der ersten Auswahl ausgewählt ist
        if (!selectedModelImage) {
            setLoadingMessageModelPrompt('Please select an image for reference.'); // Fehlermeldung setzen
            return; // Beende die Funktion, wenn kein Bild ausgewählt ist
        }

        console.log("Selected Model Image:", selectedModelImage); // Debugging-Log

        if (user) {
            setLoadingMessageModelPrompt('Sending images...'); // Ladeanzeige setzen
            try {
                const url = window.location.href; // Aktuelle URL abrufen
                const urlParts = url.split('/'); // URL in Teile aufteilen
                const docRefIdFromUrl = urlParts[7]; // docRefId ist der siebte Teil

                // Überprüfe, ob die URL eine Image-ID hat
                let imageRef; // Declare imageRef here

                if (docRefIdFromUrl) {
                    // Referenz auf das Firestore-Dokument
                    imageRef = doc(collection(firestore, "users", user.uid, "images"), docRefIdFromUrl);

                    // Überprüfe, ob das Dokument existiert
                    const docSnap = await getDoc(imageRef);
                    if (docSnap.exists()) {
                        // Dokument existiert, aktualisiere die Felder
                        await updateDoc(imageRef, {
                            selectedModelUrl: selectedModelImage,
                            modelPrompt: modelPrompt,
                            updatedAt: serverTimestamp(), // Optional: Zeitstempel für die Aktualisierung
                            promptModelPredictionStatus: 'newRequest' // Setze den Status auf 'starting'
                        });
                        console.log("Document updated with selectedModelUrl and modelPrompt.");
                    } else {
                        console.error("Document does not exist. Cannot update.");
                        setLoadingMessageModelPrompt('Document does not exist. Cannot update.'); // Fehlermeldung setzen
                    }
                } else {
                    // Wenn keine Image-ID vorhanden ist, erstelle einen neuen Eintrag
                    const imagesCollectionRef = collection(firestore, "users", user.uid, "images");

                    // Erstelle den neuen Eintrag
                    const imageData = {
                        userId: user.uid,
                        createdAt: serverTimestamp(),
                        status: "created",
                        creditCost: 1, // Assuming a default credit cost
                        selectedModelUrl: selectedModelImage, // Füge die URL des ausgewählten Bildes hinzu
                        modelPrompt: modelPrompt, // Füge den Prompt hinzu
                        promptModelPredictionStatus: 'newRequest' // Setze den Status auf 'starting'
                    };

                    // Füge das Bild in die Firestore-Sammlung ein
                    const docRef = await addDoc(imagesCollectionRef, imageData);
                    console.log('Image entry created in Firestore:', docRef.id);

                    // Setze die outputUrl mit userId und docRef.id
                    const newOutputUrl = `/tryon/user/${user.uid}/image/${docRef.id}`;

                    // Lösche die bestehende URL und setze die neue URL
                    window.history.replaceState({}, '', newOutputUrl); // Ändere die URL im Browser

                    // Setze die outputUrl
                    setOutputUrl(newOutputUrl); // Setze die outputUrl

                    // Set imageRef to the newly created document reference
                    imageRef = docRef; // Assign the new document reference to imageRef
                }

                // Überwache das Dokument auf Änderungen
                const unsubscribe = onSnapshot(imageRef, async (doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        console.log(`Monitoring entry for User ID: ${user.uid} and Image ID: ${doc.id}`); // Ausgabe der userId und imageId
                        
                        // Überprüfen, ob promptModelPredictionStatus auf 'starting' gesetzt ist
                        if (data && data.promptModelPredictionStatus === 'starting') {
                            setLoadingMessageModelPrompt('Generating images...'); // Ladeanzeige setzen
                        } 
                        // Überprüfen, ob promptModelPredictionStatus auf 'processing' gesetzt ist
                        else if (data && data.promptModelPredictionStatus === 'processing') {
                            setLoadingMessageModelPrompt('Generating images...'); // Verarbeite Bilder-Nachricht setzen
                        }
                        // Überprüfen, ob promptModelPredictionStatus auf 'successful' gesetzt ist
                        else if (data && data.promptModelPredictionStatus === 'successful') {
                            setLoadingMessageModelPrompt('Images generated successfully!'); // Erfolgsnachricht setzen
                            
                            // Setze die generierten Bild-URLs in den Zustand
                            const generatedModelImageUrls = data.promptedModelImageUrl; // Angenommen, dies ist ein Array von URLs
                            setGeneratedModelImageUrls(generatedModelImageUrls); // Setze die URLs in den Zustand

                            // Überprüfen, ob der Status nicht bereits auf 'default' gesetzt ist
                            if (data.promptModelPredictionStatus !== 'default') {
                                // Setze den Status in Firestore auf den Standardwert zurück
                                await updateDoc(imageRef, {
                                    promptModelPredictionStatus: 'default' // Setze den Status auf 'default'
                                });
                            }
                            
                            unsubscribe(); // Stoppe das Überwachen
                        }

                        // Debugging-Ausgabe für den aktuellen Status
                        console.log(`Current promptModelPredictionStatus: ${data.promptModelPredictionStatus}`);
                    } else {
                        console.error("Document does not exist.");
                    }
                });
            } catch (error) {
                console.error('Error saving model prompt:', error);
                setLoadingMessageModelPrompt('Error generating images.'); // Fehlermeldung setzen
            }
        } else {
            console.error('No user is logged in.');
            setLoadingMessageModelPrompt('Please log in to generate images.'); // Hinweis für nicht angemeldete Benutzer
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            {/* Linke Spalte: Auswahl des Modells */}
            <div style={{ flex: 1, marginRight: '20px' }}>
                <h2>1. Choose Model Image</h2>
                <div className="image-selector" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <img 
                        src={IMAGE_URL_1} 
                        alt="Model 1" 
                        className={`selectable ${selectedModelImage === IMAGE_URL_1 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_1)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === IMAGE_URL_1 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                    <img 
                        src={IMAGE_URL_2} 
                        alt="Model 2" 
                        className={`selectable ${selectedModelImage === IMAGE_URL_2 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_2)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === IMAGE_URL_2 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                    <img 
                        src={IMAGE_URL_3} 
                        alt="Model 3" 
                        className={`selectable ${selectedModelImage === IMAGE_URL_3 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_3)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === IMAGE_URL_3 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                </div>

                {/* Neue Reihe mit drei Bildern direkt darunter */}
                <div className="image-selector" style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '20px' }}>
                    <img 
                        src={ADDITIONAL_IMAGE_URL_1} 
                        alt="Additional Image 1" 
                        className={`selectable ${selectedModelImage === ADDITIONAL_IMAGE_URL_1 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(ADDITIONAL_IMAGE_URL_1)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === ADDITIONAL_IMAGE_URL_1 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                    <img 
                        src={ADDITIONAL_IMAGE_URL_2} 
                        alt="Additional Image 2" 
                        className={`selectable ${selectedModelImage === ADDITIONAL_IMAGE_URL_2 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(ADDITIONAL_IMAGE_URL_2)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === ADDITIONAL_IMAGE_URL_2 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                    <img 
                        src={ADDITIONAL_IMAGE_URL_3} 
                        alt="Additional Image 3" 
                        className={`selectable ${selectedModelImage === ADDITIONAL_IMAGE_URL_3 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(ADDITIONAL_IMAGE_URL_3)} 
                        style={{ 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto', 
                            border: selectedModelImage === ADDITIONAL_IMAGE_URL_3 ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                        }} 
                    />
                </div>

                <h2 style={{ marginTop: '20px' }}>2. Optional: Modify the images by describing how they should look.</h2>
                <input 
                    type="text" 
                    placeholder="Describe how the images should look (e.g., A European woman wearing an evening dress with a skyline in the background)" 
                    style={{ width: '100%', padding: '10px', marginTop: '10px' }} 
                    value={modelPrompt} // Eingabewert an den Zustand gebunden
                    onChange={(e) => setModelPrompt(e.target.value)} // Zustand aktualisieren
                />
                <button 
                    style={{ marginTop: '10px', padding: '10px', width: '100%' }} 
                    onClick={handleGenerateModelImages} // Funktion beim Klicken aufrufen
                >
                    Generate Model Images
                </button>
                {loadingMessageModelPrompt && (
                    <p style={{ color: loadingMessageModelPrompt.includes('Please select') ? 'red' : 'blue' }}>
                        {loadingMessageModelPrompt}
                    </p>
                )}

                {loadingMessageModelPrompt === 'Images generated successfully!' && generatedModelImageUrls.length > 0 && (
                    <div>
                        <h3>Generated Images:</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
                            {generatedModelImageUrls.map((url, index) => (
                                <img 
                                    key={index} 
                                    src={url} 
                                    alt={`Generated Image ${index}`} 
                                    className={`selectable ${selectedModelImage === url ? 'selected' : ''}`} 
                                    onClick={() => handleImageSelect(url)} // Hier wird die URL zur Auswahl hinzugefügt
                                    style={{ 
                                        maxWidth: '30%', 
                                        height: 'auto', 
                                        cursor: 'pointer', 
                                        border: selectedModelImage === url ? '2px solid blue' : 'none' // Rahmen für ausgewählte Bilder
                                    }} 
                                />
                            ))}
                        </div>
                    </div>
                )}

                <h2 style={{ marginTop: '20px' }}>3. Drop Image of Necklace</h2>
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                        border: '2px dashed #ccc',
                        borderRadius: '20px',
                        width: '100%',
                        height: '200px',
                        textAlign: 'center',
                        padding: '20px',
                        position: 'relative',
                        boxSizing: 'border-box',
                    }}
                >
                    <p>Drag your image here</p>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {image && (
                        <img 
                            src={image} 
                            alt="Preview" 
                            style={{ 
                                display: 'block', 
                                margin: '0 auto', 
                                maxWidth: '100%', 
                                maxHeight: '100%', 
                                objectFit: 'contain' 
                            }} 
                        />
                    )}
                    <button onClick={handleGoClick} style={{ marginTop: '20px' }}>
                        Upload Image
                    </button>
                </div>
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            </div>

            {/* Rechte Spalte: Koordinatensystem und hochskaliertes Bild */}
            <div style={{ flex: 1 }}>
                <h2>Coordinate System</h2>
                <CoordinateSystem 
                    newModelImage={selectedModelImage} 
                    imageUrlRb={imageUrlRb} 
                />
                
                {/* Anzeige des hochskalierten Bildes unter dem Koordinatensystem */}
                {upscaledImageUrl && (
                    <div style={{ marginTop: '20px' }}>
                        <h2>Upscaled Image Preview</h2>
                        <img 
                            src={upscaledImageUrl} 
                            alt="Upscaled Preview" 
                            style={{ 
                                display: 'block', 
                                margin: '0 auto', 
                                maxWidth: '100%', 
                                maxHeight: '100%', 
                                objectFit: 'contain' 
                            }} 
                        />
                    </div>
                )}
            </div>

            {combinedImage && (
                <div>
                    <h2>Combined Image Preview</h2>
                    <img 
                        src={combinedImage} 
                        alt="Combined Preview" 
                        style={{ 
                            display: 'block', 
                            margin: '0 auto', 
                            maxWidth: '100%', 
                            maxHeight: '100%', 
                            objectFit: 'contain' 
                        }} 
                    />
                </div>
            )}
        </div>
    );
};

export default Tryon;