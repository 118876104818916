// src/CoordinateSystem.js
import React, { useEffect, useState, useRef } from 'react';
import { uploadToR2 } from './uploadToR2'; // Importiere die uploadToR2 Funktion
import { firestore, addDoc } from "./firebase"; // Import Firestore functions
import { serverTimestamp, onSnapshot, query, orderBy, collection, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore functions
import { db } from './firebase'; // Importieren Sie Ihre Firebase-Instanz

const CoordinateSystem = ({ newModelImage, imageUrlRb, upscaledImageUrl }) => {
    const [images, setImages] = useState([]);
    const [dragging, setDragging] = useState(null);
    const [resizing, setResizing] = useState(null);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
    const [combinedImageUrl, setCombinedImageUrl] = useState(null); // Zustand für das kombinierte Bild
    const [combinedImageLink, setCombinedImageLink] = useState(null); // Zustand für das kombinierte Bild
    const [combinedCutOut, setCombinedCutOut] = useState(null); // Zustand für das kombinierte Bild
    const coordinateSystemRef = useRef(null); // Referenz für das Koordinatensystem
    const [originalDimensions, setOriginalDimensions] = useState({}); // Zustand für Originaldimensionen
    const [error, setError] = useState(null); // Zustand für Fehler
    const [userId, setUserId] = useState(null); // Zustand für die Benutzer-ID
    const [docRefId, setDocRefId] = useState(null); // Zustand für die Dokumentreferenz-ID
    const [maskUrl, setMaskUrl] = useState(null); // Zustand für das maskUrl-Bild
    const [combinedMaskUrl, setCombinedMaskUrl] = useState(null); // Zustand für das kombinierte Maskenbild
    const [processedImageUrl, setProcessedImageUrl] = useState(null); // Zustand für die processedImageUrl

    // Kombiniere processedImageUrl mit imageUrlRb
    const [finalImageUrl, setFinalImageUrl] = useState(null);

    // Zustand für den Dreh-Index
    const [rotating, setRotating] = useState(null); // Zustand für das Drehen
    const [rotationStart, setRotationStart] = useState(0); // Zustand für den Startwinkel

    // Deklaration der State-Variable
    const [combinedEmptySquareImageUrl, setCombinedEmptySquareImageUrl] = useState(null);
    const [combinedEmptySquareLink, setCombinedEmptySquareLink] = useState(null); // Zustand für das kombinierte leere Quadrat-Bild
   // const [combinedSquareImageUrl, setCombinedSquareImageUrl] = useState(null); // Zustand für das kombinierte Quadrat-Bild

    //   useEffect(() => {
        // const combineImagesIfAvailable = async () => {
           // if (processedImageUrl && imageUrlRb) {
              //  const combinedImageUrl = await combineImages(processedImageUrl, imageUrlRb);
               // setFinalImageUrl(combinedImageUrl); // Setze die kombinierte URL in den Zustand
            //}
        //};

       // combineImagesIfAvailable(); // Rufe die Funktion auf, um die Bilder zu kombinieren
    //}, [processedImageUrl, imageUrlRb]); // Abhängigkeiten: Wenn processedImageUrl oder imageUrlRb sich ändern

    // Funktion zum Überprüfen, ob die maskUrl in Firestore vorhanden ist
    const checkMaskUrlExists = async (userId, docRefId) => {
        const docRef = doc(firestore, 'users', userId, 'images', docRefId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return data.maskUrl; // Gibt die maskUrl zurück, wenn sie vorhanden ist
        }
        return null; // Gibt null zurück, wenn das Dokument nicht existiert
    };

    // Funktion zum Warten auf die maskUrl
    const waitForMaskUrl = async (userId, docRefId) => {
        let maskUrl = null;
        while (!maskUrl) {
            maskUrl = await checkMaskUrlExists(userId, docRefId);
            if (!maskUrl) {
                console.log('Waiting for maskUrl to be available...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Warte 2 Sekunden, bevor du erneut überprüfst
            }
        }
        return maskUrl; // Gibt die maskUrl zurück, wenn sie verfügbar ist
    };

    // Funktion zum Überprüfen, ob die processedImageUrl in Firestore vorhanden ist
    const checkProcessedImageUrlExists = async (userId, docRefId) => {
        const docRef = doc(firestore, 'users', userId, 'images', docRefId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return data.processedImageUrl; // Gibt die processedImageUrl zurück, wenn sie vorhanden ist
        }
        return null; // Gibt null zurück, wenn das Dokument nicht existiert
    };

    // Funktion zum Warten auf die processedImageUrl
    const waitForProcessedImageUrl = async (userId, docRefId) => {
        let processedImageUrl = null;
        while (!processedImageUrl) {
            processedImageUrl = await checkProcessedImageUrlExists(userId, docRefId);
            if (!processedImageUrl) {
                console.log('Waiting for processedImageUrl to be available...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Warte 2 Sekunden, bevor du erneut überprüfst
            }
        }
        return processedImageUrl; // Gibt die processedImageUrl zurück, wenn sie verfügbar ist
    };

    // useEffect, um die URL auszulesen, die userId und docRefId zu setzen und das imageUrlRb in das images-Array zu laden
    useEffect(() => {
        const url = window.location.href; // Aktuelle URL abrufen
        console.log('Current URL:', url); // Debugging-Log
        const urlParts = url.split('/'); // URL in Teile aufteilen
        console.log('URL Parts:', urlParts); // Debugging-Log

        // Überprüfen, ob die URL die erwartete Struktur hat
        if (urlParts.length >= 7) { // Hier auf 7 Teile prüfen
            const userIdFromUrl = urlParts[5]; // userId ist der fünfte Teil
            const docRefIdFromUrl = urlParts[7]; // docRefId ist der siebte Teil

            setUserId(userIdFromUrl); // Setze die Benutzer-ID
            setDocRefId(docRefIdFromUrl); // Setze die Dokumentreferenz-ID

            console.log('User ID:', userIdFromUrl);
            console.log('Document Reference ID:', docRefIdFromUrl);

            // Funktion zum Überprüfen, ob die maskUrl vorhanden ist
            const checkAndLoadImage = async () => {
                const maskUrl = await checkMaskUrlExists(userIdFromUrl, docRefIdFromUrl);
                if (maskUrl) {
                    // Wenn maskUrl vorhanden ist, lade imageUrlRb
                    if (imageUrlRb) {
                        const img = new Image();
                        img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                        img.src = imageUrlRb; // Setze die Bildquelle

                        img.onload = () => {
                            console.log(`Loaded imageUrlRb: ${imageUrlRb} with dimensions: ${img.width}x${img.height}`);
                            const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                            const scaledHeight = 400; // Höhe des Koordinatensystems
                            const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe

                            // Speichere die Originalauflösung mit der URL als Schlüssel
                            setOriginalDimensions(prev => ({
                                ...prev,
                                [imageUrlRb]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                            }));

                            // Setze die Originalbreite und -höhe, wenn das Bild geladen ist
                            setImages(prevImages => {
                                const imageIndex = prevImages.findIndex(image => image.url === imageUrlRb);
                                if (imageIndex === -1) {
                                    return [
                                        ...prevImages,
                                        { url: imageUrlRb, x: 150, y: 150, width: scaledWidth, height: scaledHeight }, // Skaliert
                                    ];
                                }
                                return prevImages; // Wenn es bereits vorhanden ist, nichts ändern
                            });
                        };

                        img.onerror = () => {
                            console.error(`Failed to load imageUrlRb: ${imageUrlRb}`);
                        };
                    }
                } else {
                    console.log('maskUrl is not available yet. Retrying...');
                    setTimeout(checkAndLoadImage, 2000); // Versuche es nach 2 Sekunden erneut
                }
            };

            checkAndLoadImage(); // Überprüfe und lade das Bild

            // Überprüfe die processedImageUrl, wenn die Vorhersage erfolgreich ist
            const fetchProcessedImageUrl = async () => {
                const processedImageUrl = await checkProcessedImageUrlExists(userIdFromUrl, docRefIdFromUrl);
                if (processedImageUrl) {
                    setProcessedImageUrl(processedImageUrl); // Setze die URL in den Zustand
                    console.log('Processed Image URL is now available:', processedImageUrl);
                }
            };

            fetchProcessedImageUrl(); // Rufe die Funktion auf, um die URL abzurufen
        } else {
            console.error('URL does not have the expected structure.');
        }
    }, [imageUrlRb, userId, docRefId]); // Abhängigkeit: Wenn imageUrlRb, userId oder docRefId sich ändern

        // useEffect, um das newModelImage in das images-Array zu laden oder zu ersetzen
        useEffect(() => {
            console.log("New model image selected:", newModelImage);
            console.log("Image URL for RB:", imageUrlRb);
            if (newModelImage) {
                const img = new Image();
                img.src = newModelImage; // Bildquelle setzen
    
                img.onload = () => {
                    console.log(`Loaded newModelImage: ${newModelImage} with dimensions: ${img.width}x${img.height}`);
                    const aspectRatio = img.width / img.height; // Berechne das Seitenverhältnis
                    const scaledHeight = 400; // Höhe des Koordinatensystems
                    const scaledWidth = scaledHeight * aspectRatio; // Berechne die Breite basierend auf der Höhe
    
                    // Speichere die Originalauflösung mit der URL als Schlüssel
                    setOriginalDimensions(prev => ({
                        ...prev,
                        [newModelImage]: { width: img.width, height: img.height }, // Speichere die Originalgröße
                    }));
    
                    // Überprüfen, ob das Bild bereits im Array vorhanden ist
                    const imageIndex = images.findIndex(image => image.url === newModelImage);
                    if (imageIndex !== -1) {
                        // Wenn das Bild bereits vorhanden ist, aktualisiere nur die Position
                        setImages(prevImages => {
                            const updatedImages = [...prevImages];
                            updatedImages[imageIndex] = {
                                ...updatedImages[imageIndex],
                                x: 50, // Neue Position, wenn nötig
                                y: 50, // Neue Position, wenn nötig
                            };
                            return updatedImages;
                        });
                    } else {
                        // Wenn das Bild nicht vorhanden ist, füge es mit den skalierten Abmessungen hinzu
                        setImages((prevImages) => {
                            const updatedImages = prevImages.filter(image => image.url === imageUrlRb);
                            return [
                                { url: newModelImage, x: 50, y: 50, width: scaledWidth, height: scaledHeight }, // Skaliert
                                ...updatedImages, // Behalte nur das Bild mit imageUrlRb
                            ];
                        });
                    }
                };
    
                img.onerror = () => {
                    console.error(`Failed to load newModelImage: ${newModelImage}`);
                };
            }
        }, [newModelImage]);

    useEffect(() => {
        const fetchMaskUrl = async () => {
            if (userId && docRefId) {
                const docRef = doc(firestore, 'users', userId, 'images', docRefId);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const maskUrl = data.maskUrl; // Angenommen, maskUrl ist der Schlüssel in Firestore
                    setMaskUrl(maskUrl); // Setze den maskUrl in den Zustand
                } else {
                    console.error('No such document!');
                }
            }
        };

        fetchMaskUrl();
    }, [userId, docRefId]); // Abhängigkeiten

    const handleMouseDown = (index, e) => {
        setDragging(index);
        setOffset({ x: e.clientX - images[index].x, y: e.clientY - images[index].y });
    };

    const handleMouseUp = () => {
        setDragging(null);
        setResizing(null);
        setRotating(null); // Setze den Zustand für das Drehen zurück
    };

    const handleMouseMove = (e) => {
        if (dragging !== null) {
            const newImages = [...images];
            if (dragging >= 0 && dragging < newImages.length) {
                newImages[dragging].x = e.clientX - offset.x; // Aktualisiere die x-Position
                newImages[dragging].y = e.clientY - offset.y; // Aktualisiere die y-Position
                setImages(newImages);
            }
        } else if (resizing !== null) {
            const newImages = [...images];
            if (resizing >= 0 && resizing < newImages.length) {
                const deltaX = e.clientX - resizeStart.x; // Berechne die Änderung in der X-Richtung
                const deltaY = e.clientY - resizeStart.y; // Berechne die Änderung in der Y-Richtung

                const aspectRatio = newImages[resizing].width / newImages[resizing].height;
                newImages[resizing].width = Math.max(newImages[resizing].width + deltaX, 20);
                newImages[resizing].height = Math.max(newImages[resizing].width / aspectRatio, 20);

                setImages(newImages);
                setResizeStart({ x: e.clientX, y: e.clientY }); // Aktualisiere die Startposition
            }
        } else if (rotating !== null) {
            const rect = coordinateSystemRef.current.getBoundingClientRect();
            const centerX = images[rotating].x + images[rotating].width / 2; // Mittelpunkt des Bildes
            const centerY = images[rotating].y + images[rotating].height / 2; // Mittelpunkt des Bildes
            const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX); // Berechne den aktuellen Winkel
            const rotationDelta = angle - rotationStart; // Berechne die Änderung des Winkels
            const newRotation = (images[rotating].rotation || 0) + rotationDelta; // Aktualisiere die Rotation
            setImages(prevImages => {
                const newImages = [...prevImages];
                newImages[rotating].rotation = newRotation; // Setze die neue Rotation
                return newImages;
            });
            setRotationStart(angle); // Setze den neuen Startwinkel
        }
    };

    const handleResizeMouseDown = (index, e) => {
        e.stopPropagation(); // Verhindert, dass das MouseDown-Ereignis das Bild zieht
        setResizing(index); // Setze den Index des Bildes, das resized wird
        setResizeStart({ x: e.clientX, y: e.clientY }); // Setze die Startposition für das Resize
    };

    const handleRotateMouseDown = (index, e) => {
        e.stopPropagation(); // Verhindert, dass das MouseDown-Ereignis das Bild zieht
        // Nur die Drehfunktion für imageUrlRb aktivieren
        if (images[index].url === imageUrlRb) {
            setRotating(index); // Setze den Index des Bildes, das gedreht wird
            const rect = coordinateSystemRef.current.getBoundingClientRect();
            const centerX = images[index].x + images[index].width / 2; // Mittelpunkt des Bildes
            const centerY = images[index].y + images[index].height / 2; // Mittelpunkt des Bildes
            const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX); // Berechne den Startwinkel
            setRotationStart(angle); // Setze den Startwinkel
        }
    };

    // Funktion zum Aktualisieren des Firestore-Dokuments
    const updateImageInFirestore = async (userId, docRefId, combinedImageLink, combinedMaskUrl, combinedEmptySquareLink, combinedCutOutWidth, combinedCutOutHeight) => {
        try {
            if (!userId || !docRefId) {
                console.error('userId or docRefId is undefined:', { userId, docRefId });
                return; // Beende die Funktion, wenn userId oder docRefId undefined ist
            }

            const imageRef = doc(firestore, 'users', userId, 'images', docRefId); // Verwende die Benutzer-ID und Dokumentreferenz-ID
            const docSnapshot = await getDoc(imageRef); // Überprüfe, ob das Dokument existiert

            if (docSnapshot.exists()) {
                await updateDoc(imageRef, {
                    combinedImageLink: combinedImageLink, // Speichere die combinedImageUrl
                    combinedMaskUrl: combinedMaskUrl, // Speichere die combinedMaskUrl
                    combinedEmptySquareLink: combinedEmptySquareLink, // Speichere die combinedEmptySquareLink
                    combinedCutOutWidth: combinedCutOutWidth, // Speichere die Breite als combinedCutOutWidth
                    combinedCutOutHeight: combinedCutOutHeight // Speichere die Höhe als combinedCutOutHeight
                });
                console.log(`Updated Firestore document for user ${userId} and image ${docRefId} with combinedImageUrl: ${combinedImageLink}`);
            } else {
                console.log(`Document does not exist for user ${userId} and image ${docRefId}.`);
            }
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    // Funktion zum Speichern der Bildposition und -größe in Firestore
    const saveImagePositionAndSizeToFirestore = async (userId, docRefId, drawX, drawY, drawWidth, drawHeight, modelWidth, modelHeight, rotation) => {
        const imageRef = doc(firestore, 'users', userId, 'images', docRefId);
        try {
            await updateDoc(imageRef, {
                imagePositionX: drawX,
                imagePositionY: drawY,
                imageWidthX: drawWidth,
                imageHeightY: drawHeight,
                modelWidthX: modelWidth,
                modelHeightY: modelHeight,
                rotation: rotation // Speichere die Rotation
            });
            console.log(`Image position and size saved to Firestore: Position: (${drawX}, ${drawY}), Size: (${drawWidth}, ${drawHeight}), Model Size: (${modelWidth}, ${modelHeight}), Rotation: ${rotation}`);
        } catch (error) {
            console.error('Error saving image position and size to Firestore:', error);
        }
    };

    // Funktion zum Kombinieren und Hochladen des Model- und Image-Bildes
    const combineAndUploadImages = async (imageSize = 1024) => { // Standardwert auf 1024 setzen
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Setze die Canvas-Größe auf den angegebenen Wert
        canvas.width = imageSize; 
        canvas.height = imageSize; 

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        let combinedImageLink = null;
        let combinedMaskLink = null; // Deklaration der combinedMaskLink
        let combinedEmptySquareLink = null; // Deklaration der combinedEmptySquareLink

        if (newImage) {
            // Berechne die Skalierungsfaktoren
            const scaleX = canvas.width / newImage.width; // Berechne den Skalierungsfaktor X
            const scaleY = canvas.height / newImage.height; // Berechne den Skalierungsfaktor Y

            // Berechne die Originalgrößen für die Speicherung
            const modelImageSizeX = newImage.width; // Breite des newModelImage
            const modelImageSizeY = newImage.height; // Höhe des newModelImage

            // Definiere die Positionen der Bilder
            const modelImagePosition = images.find(image => image.url === newModelImage);
            const imageRbPosition = images.find(image => image.url === imageUrlRb);

            // Zeichne jedes Bild auf das Canvas mit den entsprechenden Positionen und Größen
            for (const image of images) {
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // Setze das crossOrigin-Attribut
                img.src = image.url;

                await new Promise((resolve, reject) => {
                    img.onload = async () => {
                        // Berechne die neue Position und Größe
                        const drawX = (image.x - newImage.x) * scaleX; // Berechne die neue X-Position
                        const drawY = (image.y - newImage.y) * scaleY; // Berechne die neue Y-Position
                        const drawWidth = image.width * scaleX; // Berechne die neue Breite
                        const drawHeight = image.height * scaleY; // Berechne die neue Höhe

                        // Setze den Ursprung der Transformation auf die Mitte des Bildes
                        ctx.save(); // Speichere den aktuellen Zustand des Kontextes
                        ctx.translate(drawX + drawWidth / 2, drawY + drawHeight / 2); // Verschiebe den Ursprung
                        ctx.rotate((image.rotation || 0)); // Drehe das Bild
                        ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight); // Zeichne das Bild relativ zum neuen Ursprung
                        ctx.restore(); // Stelle den vorherigen Zustand des Kontextes wieder her

                        resolve();
                    };
                    img.onerror = (error) => {
                        console.error('Error loading image:', error);
                        reject(error); // Stelle sicher, dass die Promise abgelehnt wird
                    };
                });
            }

            const combinedImageUrl = canvas.toDataURL('image/png');
            setCombinedImageUrl(combinedImageUrl);

            // Hochladen des kombinierten Bildes an Cloudflare R2
            const blob = await (await fetch(combinedImageUrl)).blob();
            try {
                combinedImageLink = await uploadToR2(blob); // Hochladen an Cloudflare R2
                setCombinedImageUrl(combinedImageUrl); // Setze die combinedImageUrl
                console.log('Uploaded Combined Image URL:', combinedImageLink);

                // Ausgabe der Position, der skalierte Größe und der Rotation des model und des Image Bildes
                console.log(`Model Image Position: (X: ${modelImagePosition.x}, Y: ${modelImagePosition.y}), Size: (Width: ${modelImagePosition.width}, Height: ${modelImagePosition.height}), Rotation: ${modelImagePosition.rotation || 0}`);
                console.log(`Image URL Rb Position: (X: ${imageRbPosition.x}, Y: ${imageRbPosition.y}), Size: (Width: ${imageRbPosition.width}, Height: ${imageRbPosition.height}), Rotation: ${imageRbPosition.rotation || 0}`);

                // Rufe die Funktion zum Kombinieren des Maskenbildes und des leeren Quadrat auf
                combinedMaskLink = await combineMaskAndUploadImages(); // Stelle sicher, dass diese Funktion aufgerufen wird

                // Überprüfe, ob das combinedMaskLink erfolgreich erstellt wurde, bevor du das leere Quadrat kombinierst
                if (combinedMaskLink) {
                    combinedEmptySquareLink = await combineEmptySquareAndUploadImages(); // Kombiniere und lade das Bild mit dem farblosen Rechteck hoch
                }

                // Speichere die combinedImageUrl, combinedMaskUrl und combinedEmptySquareLink in Firestore, wenn alle Links vorhanden sind
                if (combinedImageLink && combinedMaskLink && combinedEmptySquareLink) {
                    await updateImageInFirestore(userId, docRefId, combinedImageLink, combinedMaskLink, combinedEmptySquareLink, modelImageSizeX, modelImageSizeY); // Speichere die URLs und Größen
                    console.log('Combined Image Link saved successfully.');
                }
            } catch (error) {
                console.error('Error uploading combined image to Cloudflare:', error);
                setError('Failed to upload combined image.'); // Benutzerbenachrichtigung
            }
        }
    };

    // Funktion zum Kombinieren von Maske und weißem Rechteck Hochladen der Bilder
    const combineMaskAndUploadImages = async (imageSize = 1024) => { // Standardwert auf 1024 setzen
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Setze die Canvas-Größe auf den angegebenen Wert
        canvas.width = imageSize; 
        canvas.height = imageSize; 

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        const cutOutImage = images.find(image => image.url === imageUrlRb); // Finde das CutOut-Bild

        if (newImage) {
            // Warten auf die maskUrl
            const maskUrl = await waitForMaskUrl(userId, docRefId); // Warten, bis die maskUrl verfügbar ist

            // Zeichne ein weißes Rechteck auf das Canvas
            ctx.fillStyle = 'white'; // Setze die Füllfarbe auf weiß
            ctx.fillRect(0, 0, canvas.width, canvas.height); // Zeichne das Rechteck in der Größe des kombinierten Bildes

            // Zeichne die Maske an der Position und Größe von imageUrlRb
            if (cutOutImage && maskUrl) {
                const maskImg = new Image();
                maskImg.crossOrigin = 'Anonymous';
                maskImg.src = maskUrl; // Verwende maskUrl

                await new Promise((resolve, reject) => {
                    maskImg.onload = () => {
                        // Berechne die neue Position und Größe der Maske
                        const drawX = (cutOutImage.x - newImage.x) * (canvas.width / newImage.width); // Berechne die neue X-Position
                        const drawY = (cutOutImage.y - newImage.y) * (canvas.height / newImage.height); // Berechne die neue Y-Position
                        const drawWidth = cutOutImage.width * (canvas.width / newImage.width); // Berechne die neue Breite
                        const drawHeight = cutOutImage.height * (canvas.height / newImage.height); // Berechne die neue Höhe

                        // Setze den Ursprung der Transformation auf die Mitte der Maske
                        ctx.save(); // Speichere den aktuellen Zustand des Kontextes
                        ctx.translate(drawX + drawWidth / 2, drawY + drawHeight / 2); // Verschiebe den Ursprung
                        ctx.rotate((cutOutImage.rotation || 0)); // Drehe die Maske
                        ctx.drawImage(maskImg, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight); // Zeichne die Maske relativ zum neuen Ursprung
                        ctx.restore(); // Stelle den vorherigen Zustand des Kontextes wieder her

                        resolve();
                    };
                    maskImg.onerror = reject;
                });
            }

            const finalCombinedImageUrl = canvas.toDataURL('image/png');
            // Hochladen des kombinierten Maskenbildes an Cloudflare R2
            const blob = await (await fetch(finalCombinedImageUrl)).blob();
            try {
                const link = await uploadToR2(blob); // Hochladen an Cloudflare R2
                console.log('Uploaded Combined Mask URL:', link);
                setCombinedMaskUrl(link); // Setze die combinedMaskUrl
                return link; // Gib die URL zurück
            } catch (error) {
                console.error('Error uploading combined mask to Cloudflare:', error);
                setError('Failed to upload combined mask.'); // Benutzerbenachrichtigung
            }
        }
        return null; // Gebe null zurück, wenn kein Bild vorhanden ist
    };

    // Funktion zum Kombinieren von imageUrlRb und einem leeren Quadrat und Hochladen der Bilder
    const combineEmptySquareAndUploadImages = async (imageSize = 2024) => { // Standardwert auf 2000 setzen
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Setze die Canvas-Größe auf den angegebenen Wert
        canvas.width = imageSize; 
        canvas.height = imageSize; 

        // Kombiniere das newModelImage
        const newImage = images.find(image => image.url === newModelImage);
        const cutOutImage = images.find(image => image.url === imageUrlRb); // Finde das CutOut-Bild

        if (newImage) {
            // Warten auf die maskUrl
            const maskUrl = await waitForMaskUrl(userId, docRefId); // Warten, bis die maskUrl verfügbar ist

            // Zeichne das leere Quadrat in der Größe des kombinierten Bildes
            ctx.fillStyle = 'rgba(255, 255, 255, 0)'; // Setze die Füllfarbe auf transparent
            ctx.fillRect(0, 0, canvas.width, canvas.height); // Zeichne das Quadrat in der Größe des kombinierten Bildes

            // Zeichne das cutOutImage an der Position und Größe von imageUrlRb
            if (cutOutImage) {
                const imgRb = new Image();
                imgRb.crossOrigin = 'Anonymous';
                imgRb.src = imageUrlRb; // Verwende imageUrlRb

                await new Promise((resolve, reject) => {
                    imgRb.onload = () => {
                        // Berechne die neue Position und Größe des cutOutImage
                        const drawX = (cutOutImage.x - newImage.x) * (canvas.width / newImage.width); // Berechne die neue X-Position
                        const drawY = (cutOutImage.y - newImage.y) * (canvas.height / newImage.height); // Berechne die neue Y-Position
                        const drawWidth = cutOutImage.width * (canvas.width / newImage.width); // Berechne die neue Breite
                        const drawHeight = cutOutImage.height * (canvas.height / newImage.height); // Berechne die neue Höhe

                        // Setze den Ursprung der Transformation auf die Mitte des Bildes
                        ctx.save(); // Speichere den aktuellen Zustand des Kontextes
                        ctx.translate(drawX + drawWidth / 2, drawY + drawHeight / 2); // Verschiebe den Ursprung
                        ctx.rotate((cutOutImage.rotation || 0)); // Drehe das Bild
                        ctx.drawImage(imgRb, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight); // Zeichne das Bild relativ zum neuen Ursprung
                        ctx.restore(); // Stelle den vorherigen Zustand des Kontextes wieder her
                        resolve();
                    };
                    imgRb.onerror = reject;
                });
            }

            // Hochladen des kombinierten Bildes (leeres Quadrat mit imageUrlRb) an Cloudflare R2
            const combinedEmptySquareLink = canvas.toDataURL('image/png'); // Umbenennung der Variablen
            const blob = await (await fetch(combinedEmptySquareLink)).blob();
            try {
                const link = await uploadToR2(blob); // Hochladen an Cloudflare R2
                console.log('Uploaded Combined Square Image URL:', link);
                setCombinedEmptySquareLink(link); // Setze den combinedEmptySquareLink
                return link; // Gib die URL zurück
            } catch (error) {
                console.error('Error uploading combined square image to Cloudflare:', error);
                setError('Failed to upload combined square image.'); // Benutzerbenachrichtigung
            }
        }
        return null; // Gebe null zurück, wenn kein Bild vorhanden ist
    };

    const handleCombineAndUpload = async () => {
        console.log('Button wurde gedrückt'); // Protokolliere den Button-Klick

        // Aufruf der Funktionen in der gewünschten Reihenfolge
        try {
            await combineAndUploadImages(); // Kombiniere und lade die Bilder hoch
           // await combineMaskAndUploadImages(); // Kombiniere und lade die Maskenbilder hoch
            //await combineEmptySquareAndUploadImages(); // Kombiniere und lade das Bild mit dem farblosen Rechteck hoch
        } catch (error) {
            console.error('Fehler beim Kombinieren und Hochladen der Bilder:', error);
        }
    };

    return (
        <div>
            <div
                ref={coordinateSystemRef} // Referenz für das Koordinatensystem
                style={{
                    border: '2px solid #ccc',
                    borderRadius: '20px',
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: '#f9f9f9',
                }}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            >
                {images.map((image, index) => (
                    <div key={index} style={{ position: 'absolute', left: image.x, top: image.y }}>
                        <img
                            src={image.url}
                            alt={`Dropped ${index}`}
                            onMouseDown={(e) => handleMouseDown(index, e)} // Bild anklicken, um es zu ziehen
                            style={{
                                width: image.width,
                                height: image.height,
                                cursor: 'move',
                                transform: image.url === imageUrlRb ? `rotate(${image.rotation || 0}rad)` : 'none', // Wende die Rotation nur auf imageUrlRb an
                                transformOrigin: 'center', // Setze den Ursprung der Transformation auf die Mitte
                            }}
                        />
                        {/* Resize Handle */}
                        <div
                            onMouseDown={(e) => handleResizeMouseDown(index, e)} // Resize Handle anklicken
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '30px', // Breite des Handles erhöhen
                                height: '30px', // Höhe des Handles erhöhen
                                backgroundColor: 'red', // Hintergrundfarbe
                                cursor: 'nwse-resize',
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%" // Füllt die Breite des Containers
                                height="100%" // Füllt die Höhe des Containers
                                viewBox="0 0 200 200" // Setze den ViewBox entsprechend
                                fill="none" // Keine Füllfarbe für den Hintergrund
                            >
                                <path d="M173.93,92.798c-4.971,0-9,4.029-9,9v50.404L30.728,18h50.404c4.971,0,9-4.029,9-9s-4.029-9-9-9H9C4.03,0,0,4.029,0,9
                                    v72.132c0,4.971,4.029,9,9,9s9-4.029,9-9V30.729l134.202,134.202h-50.404c-4.971,0-9,4.029-9,9s4.029,9,9,9h72.132
                                    c4.971,0,9-4.029,9-9v-72.132C182.93,96.828,178.901,92.798,173.93,92.798z" fill="#000000"/>
                            </svg>
                        </div>
                        {image.url === imageUrlRb && (
                            <div
                                onMouseDown={(e) => handleResizeMouseDown(index, e)} // Resize Handle anklicken
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    cursor: 'nwse-resize',
                                }}
                            />
                        )}
                        {/* Rotate Handle */}
                        {image.url === imageUrlRb && ( // Zeige das Dreh-Handle nur für imageUrlRb an
                            <div
                                onMouseDown={(e) => handleRotateMouseDown(index, e)} // Rotate Handle anklicken
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: '30px', // Breite des Handles
                                    height: '30px', // Höhe des Handles
                                    backgroundColor: 'blue', // Hintergrundfarbe
                                    borderRadius: '50%', // Runde Form
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20" // Breite des Symbols
                                    height="20" // Höhe des Symbols
                                    viewBox="0 0 20 20" // Setze den ViewBox entsprechend
                                    fill="#FF0000" // Rot
                                >
                                    <path d="M10 7L9 6L11.2929 3.70711L10.8013 3.21553C10.023 2.43724 8.96744 2 7.86677 2C4.63903 2 2 4.68015 2 7.93274C2 11.2589 4.69868 14 8 14C9.53708 14 11.0709 13.4144 12.2426 12.2426L13.6569 13.6569C12.095 15.2188 10.0458 16 8 16C3.56933 16 0 12.3385 0 7.93274C0 3.60052 3.50968 0 7.86677 0C9.49787 0 11.0622 0.647954 12.2155 1.80132L12.7071 2.29289L15 0L16 1V7H10Z" fill="#000000"/>
                                </svg>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            
            {/* Anzeige der Positionen, Größen und Rotationen unter dem Koordinatensystem */}
            {/*<div style={{ marginTop: '20px' }}>
                <h4>Image Positions, Sizes, and Rotations:</h4>
                {images.map((image, index) => {
                    const scaleFactor = 1024 / 400; // Skalierungsfaktor
                    const scaledX = (image.x - 50) * scaleFactor;
                    const scaledY = (image.y - 50) * scaleFactor;
                    const scaledWidth = image.width * scaleFactor;
                    const scaledHeight = image.height * scaleFactor;

                    return (
                        <div key={index}>
                            <p>
                                {`Image ${index + 1}: Position (X: ${image.x}, Y: ${image.y}), Size (Width: ${image.width}, Height: ${image.height})`}
                            </p>
                            <p>
                                {`Rotation: ${(image.rotation * (180 / Math.PI)).toFixed(2)}°`}
                            </p>
                            <p>
                                {`Mittelpunkt: (Center X: ${(image.x + image.width / 2).toFixed(2)}, Center Y: ${(image.y + image.height / 2).toFixed(2)})`}
                            </p>
                            <p>
                                {`Größe bezogen auf den Mittelpunkt: (Width: ${(image.width / 2).toFixed(2)}, Height: ${(image.height / 2).toFixed(2)})`}
                            </p>
                        </div>
                    );
                })}
            </div>*/}

            <button onClick={handleCombineAndUpload} style={{ marginTop: '20px' }}>
                Combine and Upload Images
            </button>

        </div>
    );
};

export default CoordinateSystem;
